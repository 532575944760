<template>
  <PhTable
    v-model="selectedRows"
    @rowClick="handleRowClick"
    :headers="headers"
    :height="height"
    :loading="loading"
    :server-items-length="totalItemsNumber"
    :sort-by.sync="sortByField"
    :sort-desc.sync="sortInDescendingOrder"
    :items="items"
  >
    <template v-slot:[`item.status`]="{ item }">
      <slot name="status" :item="item">
        <ClaimStatus :status="item.status" />
      </slot>
    </template>
    <template v-slot:[`item.view`]="{ item }">
      <v-icon @click.stop="handleViewClick(item.id)" size="20">keyboard_arrow_right</v-icon>
    </template>
  </PhTable>
</template>

<script>
import ClaimStatus from '@/components/claims/ClaimStatus';
import PhTable from '@/components/tables/PhTable';
import tableProperties from '@/mixins/tableProperties';

const DEFAULT_HEADERS = [
  {
    text: 'Full Name',
    align: 'left',
    value: 'fullName',
    sortable: true,
    width: 150,
  },
  {
    text: 'Claim number',
    value: 'claimNumber',
    sortable: false,
    width: 150,
  },
  {
    text: 'Member ID',
    value: item => {
      return (
        item.memberId ||
        (item.healthInsurancePlan &&
          item.healthInsurancePlan.length &&
          (item.healthInsurancePlan[0].healthInsuranceMemberId ||
            item.healthInsurancePlan[0].memberId)) ||
        '-'
      );
    },
    sortable: false,
    width: 100,
  },
  {
    text: 'Insurance Info',
    value: item => {
      return (
        item.insuranceCompany ||
        (item.healthInsurancePlan &&
          item.healthInsurancePlan.length &&
          item.healthInsurancePlan[0].companyName) ||
        '-'
      );
    },
    sortable: false,
    width: 105,
  },
  {
    text: 'Email',
    value: 'claimantEmail',
    sortable: false,
    width: 100,
  },
  {
    text: 'Address',
    value: 'address',
    sortable: false,
    width: 200,
  },
  {
    text: 'Status',
    value: 'status',
    sortable: false,
    width: 100,
  },
  {
    text: '',
    value: 'view',
    sortable: false,
    width: 30,
  },
];

export default {
  name: 'ClaimsTable',
  mixins: [tableProperties],
  components: {
    ClaimStatus,
    PhTable,
  },
  props: {
    headers: {
      type: Array,
      default: () => DEFAULT_HEADERS,
    },
  },
  computed: {
    totalItemsNumber() {
      return this.items instanceof Array ? this.items.length : 0;
    },
  },
  methods: {
    handleRowClick(item) {
      if (item instanceof Object && typeof item.id === 'string') {
        this.handleViewClick(item.id);
      }
    },
    handleViewClick(itemId) {
      this.$emit('itemViewClick', itemId);
    },
  },
};
</script>
