<template>
  <div class="claims-list-view">
    <v-container class="claims-list-view__container fill-height">
      <div class="claims-list-view__header">
        <v-container class="py-0">
          <v-layout
            class="claims-list-view__header-row claims-list-view__header-row--top"
            align-center
            justify-space-between
          >
            <PageTitle>Claims</PageTitle>
            <div class="claims-list-view__header-actions-group">
              <!-- <v-btn>
                + Add claim
              </v-btn> -->
            </div>
          </v-layout>
        </v-container>
        <PageFilterBar
          class="claims-list-view__header-row claims-list-view__header-row--bottom"
          paddingX
        >
          <PageFilter>
            <div>
              <SelectFilter
                fullWidth
                v-model="claimOrganizationsFilter"
                :items="organizationsOptions"
                placeholder="Organizations"
              >
              </SelectFilter>
              <div
                class="claim-list-filters__organization-info"
                v-if="claimOrganizationsFilter.length"
              >
                <div class="claim-list-filters__organization-info-label">
                  Selected {{ claimOrganizationsFilter.length }}
                  {{ claimOrganizationsFilter.length > 1 ? 'organizations' : 'organization' }}
                </div>
                <div class="claim-list-filters__organization-info-btn">
                  <v-btn text small @click="claimOrganizationsFilter = []">
                    Reset
                  </v-btn>
                </div>
              </div>
            </div>
          </PageFilter>
          <PageFilterGroup grow>
            <PageFilter type="search" wide>
              <SearchTextField v-model="claimClaimantNameFilter" placeholder="Full Name" />
            </PageFilter>
            <PageFilter type="search" wide>
              <SearchTextField v-model="claimSearchQueryFilter" placeholder="Claim Number" />
            </PageFilter>
          </PageFilterGroup>
          <PageFilterGroup justify="between">
            <PageFilter type="date-range">
              <PhDateRangePicker
                :start.sync="claimDateFromFilter"
                :end.sync="claimDateToFilter"
                rounded
              />
            </PageFilter>
            <PageFilter>
              <SelectFilter
                v-model="claimStatusFilter"
                :items="claimFilterOptions.status"
                label="Filter by"
                placeholder="Status"
              >
              </SelectFilter>
            </PageFilter>
          </PageFilterGroup>
        </PageFilterBar>
      </div>
      <div class="claims-list-view__content pb-10">
        <div>
          <ClaimsTable
            :items="computedClaims"
            :loading="loadingClaims"
            :sort-by="claimFilters.sortBy"
            :sort-desc="claimFilters.sortDesc"
            @update:sort-desc="handleSortType"
            @itemViewClick="handleShowClaim"
          />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getClaimantAddressFromClaim } from '@/utils/helpers/app/claims';
import claimFilters from '@/mixins/claimFilters';
import ClaimsTable from '@/components/tables/ClaimsTable';
import {
  PageFilter,
  PageFilterBar,
  PageFilterGroup,
  SearchTextField,
  SelectFilter,
} from '@/components/filters';
import PageTitle from '@/components/typography/PageTitle';
import PhDateRangePicker from '@/components/pickers/PhDateRangePicker';

export default {
  name: 'ClaimsList',
  mixins: [claimFilters],
  components: {
    ClaimsTable,
    PageFilter,
    PageFilterBar,
    PageFilterGroup,
    PageTitle,
    PhDateRangePicker,
    SearchTextField,
    SelectFilter,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('claims', ['claims', 'loadingClaims']),
    computedClaims() {
      if (this.claims && this.claims.length) {
        return this.claims.map(claim => {
          const fullName = `${claim.claimantFirstName} ${claim.claimantLastName}`;
          return {
            ...claim,
            fullName,
            claimantEmail: claim.claimantEmail,
            memberId: claim.memberId,
            address: this.$formatAddress(getClaimantAddressFromClaim(claim)),
          };
        });
      }
      return this.claims;
    },
  },
  watch: {
    claimFilters: {
      handler() {
        this.getClaims();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('claims', ['fetchClaims']),
    ...mapActions('app', ['openSidebarInfo']),
    async getClaims() {
      try {
        await this.fetchClaims();
        return true;
      } catch (error) {
        this.$dialog.error({ error });
        return false;
      }
    },
    handleShowClaim(id) {
      this.openSidebarInfo({
        id,
        content: 'claim',
      });
    },
  },
  mounted() {
    this.getClaims();
  },
};
</script>

<style lang="scss">
@import '~@/scss/views/claims';
</style>
